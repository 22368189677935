<template>
    <div v-if="lineItemDelivery"
         class="sm-line-item-delivery-information">
        <p class="sm-line-item-delivery-information-inner">
            <span :class="lineItemDelivery.color">
                {{ lineItemDelivery.status }}
            </span>
            <span v-if="lineItemDelivery.time" class="sm-line-item-delivery-information-seperator">|</span>
            <span v-if="lineItemDelivery.time" class="sm-line-item-delivery-information-time">
                {{ lineItemDelivery.time }}
            </span>
        </p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SmLineItemDeliveryInformation',

  props: {
    lineItem: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    }
  },

  computed: {
    lineItemDelivery() {
      const deliveryInformation = this.lineItem.deliveryInformation;
      const deliveryTime = deliveryInformation.deliveryTime;
      const quantityInformation = this.lineItem.quantityInformation;

      if (!deliveryTime || !this.lineItem) {
        return null;
      }

      if (this.lineItem.payload.customFields.K_IstNichtKaufbar) {
        return {
          color: 'text-danger',
          status: 'Lieferzeit anfragen',
          time: null
        }
      } else if (deliveryInformation.stock >= quantityInformation.minPurchase) {
        return {
          color: 'text-success',
          status: 'Lieferbar',
          time: 'Lieferung in 1-3 Werktagen'
        }
      } else if (deliveryInformation.stock < quantityInformation.minPurchase && deliveryInformation.restockTime > 100) {
        return {
          color: 'text-warning',
          status: 'Vorbestellen',
          time: 'Lieferzeit unklar'
        }
      } else if (deliveryInformation.stock < quantityInformation.minPurchase && deliveryInformation.restockTime) {
        return {
          color: 'text-success',
          status: 'Lieferbar',
          time: `Lieferbar in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else if (deliveryInformation.stock < quantityInformation.minPurchase && !deliveryInformation.restockTime) {
        return {
          color: 'text-danger',
          status: 'Lieferzeit anfragen',
          time: null
        }
      } else {
        return null;
      }
    }
  }
});
</script>

<style scoped>
.sm-line-item-delivery-information-seperator {
    display: inline-block;
    margin: 0 3px;
    color: var(--ion-color-secondary);
}
</style>
